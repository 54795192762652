/* You can add global styles to this file, and also import other style files */
body {
  margin: 0px;
  font-family: Roboto, sans-serif;
  background-color: whitesmoke;
}

// Icons
@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size !important;
  width: $size !important;
}

.large-icon {
  @include md-icon-size(48px);
}

.medium-icon {
  @include md-icon-size(32px);
}

.small-icon {
  @include md-icon-size(14px);
}
